import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { environment } from 'src/environments/environment';

import {
  MenuOption,
  OptionConfig,
  SectionConfig,
  Title,
} from '../../menu.model';
import { MenuService } from '../../menu.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { WashListComponent } from 'src/app/dashboard/wash-list/wash-list.component';

@Component({
  selector: 'app-mobile-sub-menu',
  standalone: true,
  imports: [MatTooltipModule],
  templateUrl: './mobile-sub-menu.component.html',
  styleUrl: './mobile-sub-menu.component.scss',
})
export class MobileSubMenuComponent implements OnInit {
  @Input() menuConfig: SectionConfig | OptionConfig;
  @Input() parentMenuPath: string;

  @Output() itemChosen = new EventEmitter<SectionConfig | OptionConfig>();

  isSelected: boolean;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private pwaService: PwaService
  ) {}

  ngOnInit(): void {
    this.isSelected = this.menuConfig.value.includes(
      this.menuService.getSelectedMenu()
    );

    this.router.events.subscribe(() => {
      this.isSelected = this.menuConfig.value.includes(
        this.menuService.getSelectedMenu()
      );
    });

    const isInstallOnTraxOption = this.menuConfig.label === Title.InstallOnTrax;

    if (isInstallOnTraxOption) {
      this.pwaService.appInstalled$.subscribe((isInstalled) => {
        this.menuConfig.hidden = isInstalled;
      });

      this.pwaService.beforeInstallPrompt$.subscribe((promptAvailable) => {
        this.menuConfig.hidden = !promptAvailable;
      });

      this.pwaService.isInstalling$.subscribe((isInstalling) => {
        this.menuConfig.disabled = isInstalling;
      });
    }
  }

  select() {
    if (!this.parentMenuPath || !this.menuConfig || this.menuConfig.disabled) {
      return;
    }

    const dashboardPrefix = 'dashboard';

    switch (this.parentMenuPath) {
      case MenuOption.Ncr:
        this.router.navigate([
          `/${dashboardPrefix}/${this.parentMenuPath}/list`,
          this.menuConfig.value,
        ]);
        break;

      case WashListComponent.route:
      case MenuOption.OtherTools:
      case MenuOption.BulkUpload:
        this.router.navigate([
          `/${dashboardPrefix}/${this.parentMenuPath}`,
          this.menuConfig.value,
        ]);
        break;

      case MenuOption.Home:
      case MenuOption.Schedule:
        this.router.navigate([`/${dashboardPrefix}/${this.parentMenuPath}`]);
        break;

      case MenuOption.Settings:
        if (this.menuConfig.value.includes(MenuOption.InstallOnTrax)) {
          this.pwaService.promptInstall();
          return;
        } else {
          this.router.navigate([
            `/${dashboardPrefix}/${this.menuConfig.value}`,
          ]);
        }
        break;

      case MenuOption.Reports:
      case MenuOption.ShiftLogs:
        this.router.navigate([`/${dashboardPrefix}/${this.menuConfig.value}`]);
        break;

      case MenuOption.Support:
        if (this.menuConfig.value === MenuOption.Wiki) {
          window.open(environment.wikiUrl);
        }

        if (this.menuConfig.value === MenuOption.Video) {
          window.open(environment.trainingVideoUrl);
        } else if (this.menuConfig.value === MenuOption.Terms) {
          this.router.navigate([`/${dashboardPrefix}/${MenuOption.Terms}`]);
        }

        break;
    }

    this.itemChosen.emit(this.menuConfig);
  }
}
