import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ontrax-feed',
  templateUrl: './ontrax-feed.component.html',
  styleUrls: ['./ontrax-feed.component.scss'],
})
export class OnTraxFeedComponent implements OnInit {
  static route = 'feed';

  constructor() {}

  ngOnInit(): void {}
}
