import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MainNavComponent } from 'src/app/global/main-nav/main-nav.component';
import { NcrIconComponent } from 'src/app/shared/ncr-icon/ncr-icon.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MainNavComponent,
    NcrIconComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  tiles = [
    {
      label: 'OnTrax Feed',
      icon: 'newspaper',
      link: '/dashboard/feed',
      customClasses: 'ontrax-feed',
      hidden: false,
      disabled: false,
    },
    {
      label: 'Ordering',
      icon: 'truck',
      link: '/dashboard/wash-list',
      hidden: false,
      disabled: false,
    },
    {
      label: 'Scheduling',
      icon: 'calendar-check',
      link: '/dashboard/schedule',
      hidden: false,
      disabled: true,
    },
    {
      label: 'Shift Logs',
      icon: 'address-book',
      link: '/dashboard/shift-logs',
      hidden: false,
      disabled: false,
    },
    {
      label: 'NCR',
      customIconComponent: NcrIconComponent,
      link: '/dashboard/non-conformity-report',
      hidden: false,
      disabled: false,
    },
    {
      label: 'Reports',
      icon: 'chart-column',
      link: '/dashboard/reports',
      hidden: false,
      disabled: false,
    },
    {
      label: 'Heat Management',
      icon: 'temperature-arrow-up',
      link: '/dashboard/heat-management',
      hidden: true,
      disabled: false,
    },
    {
      label: 'Yard Check',
      icon: 'walking',
      link: '/dashboard/yard-check',
      hidden: true,
      disabled: false,
    },
    {
      label: 'Bulk Upload',
      icon: 'cloud-arrow-up',
      link: '/dashboard/bulk-upload/download-template',
      hidden: false,
      disabled: false,
    },
  ];
}
