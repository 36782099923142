import { MenuOption, OptionConfig, SectionConfig, Title } from './menu.model';
import { RoleName } from './role-name.enum';
import { UserType } from './user-type.enum';

import { WashListComponent } from '../wash-list/wash-list.component';

export const getSectionDict = (): { [key: string]: Partial<OptionConfig> } => {
  const sectionDict: { [key: string]: OptionConfig } = {};

  sectionDict[MenuOption.Logout] = logOutSection;
  sectionDict[MenuOption.Home] = homeSection;
  sectionDict[MenuOption.OtherTools] = otherToolsSection;
  sectionDict[WashListComponent.route] = requestListSection;
  sectionDict[MenuOption.Schedule] = scheduleSection;
  sectionDict[MenuOption.Settings] = settingsSection;
  sectionDict[MenuOption.ShiftLogs] = shiftLogsSection;
  sectionDict[MenuOption.BulkUpload] = bulkUploadSection;
  sectionDict[MenuOption.Support] = supportSection;
  sectionDict[MenuOption.Ncr] = ncrSection;

  for (const option of reportsSection.options) {
    sectionDict[option.value] = option;
  }

  return sectionDict;
};

export const logOutSection: OptionConfig = {
  label: 'Logout',
  icon: 'sign-out-alt',
  value: MenuOption.Logout,
  visibleByUserType: [UserType.AllUserTypes],
  authorizedRoles: [RoleName.AllRoles],
};

export const homeSection: SectionConfig = {
  label: 'Home',
  icon: 'home',
  value: MenuOption.Home,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Feed',
      icon: 'newspaper',
      value: MenuOption.HomeFeed,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const otherToolsSection: SectionConfig = {
  label: 'Other Tools',
  icon: 'tools',
  value: MenuOption.OtherTools,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Datalake',
      icon: 'water',
      value: MenuOption.OtherToolsDatalake,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
    {
      label: 'Data Fabric',
      icon: 'city',
      value: MenuOption.OtherToolsDataFabric,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const requestListSection: SectionConfig = {
  label: 'OnTrax Requests',
  icon: 'list-ul',
  value: WashListComponent.route,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Exterior Wash Offer',
      icon: 'tint',
      value: MenuOption.ExteriorWashOffer,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Drafts',
      icon: 'edit',
      value: MenuOption.Draft,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Submitted',
      icon: 'paper-plane',
      value: MenuOption.Submitted,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      label: 'New Requests',
      icon: 'plus-circle',
      value: MenuOption.NewWashRequests,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Pending',
      icon: 'hourglass-half',
      value: MenuOption.Pending,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Accepted',
      icon: 'star',
      value: MenuOption.Accepted,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Credit Hold',
      icon: 'credit-card',
      value: MenuOption.CreditHold,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Needs Action',
      icon: 'exclamation-circle',
      value: MenuOption.NeedsAction,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'In Progress',
      icon: 'sync-alt',
      value: MenuOption.InProgress,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Hold',
      icon: 'hand-paper',
      value: MenuOption.Hold,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Completed',
      icon: 'check-circle',
      value: MenuOption.Completed,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Canceled',
      icon: 'ban',
      value: MenuOption.Canceled,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Rejected',
      icon: 'times-circle',
      value: MenuOption.Rejected,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Expired Requests',
      icon: 'hourglass-end',
      value: MenuOption.Expired,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Schneider Portal Completions',
      icon: 'truck',
      value: MenuOption.SchneiderPortalCompletions,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const scheduleSection: SectionConfig = {
  label: 'OnTrax Scheduling',
  icon: 'calendar',
  value: MenuOption.Schedule,
  visibleByUserType: [UserType.Internal],
  options: [
    {
      label: 'Schedule',
      icon: 'calendar',
      value: MenuOption.Schedule,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const settingsSection: SectionConfig = {
  label: 'Settings',
  icon: 'cog',
  value: MenuOption.Settings,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: Title.InstallOnTrax,
      icon: 'mobile-screen-button',
      value: `settings/${MenuOption.InstallOnTrax}`,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
    {
      label: 'Notification Preferences',
      icon: 'bell',
      value: `settings/${MenuOption.UserNotification}`,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Company Notifications',
      icon: 'bell',
      value: `settings/${MenuOption.BusinessPartnerNotification}`,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      label: 'Terminal Bays',
      icon: 'truck',
      value: MenuOption.BaySettings,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Terminal Shifts',
      icon: 'clock',
      value: MenuOption.HoursOfOperation,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Terminal Velocity',
      icon: 'tachometer-alt',
      value: MenuOption.VelocitySettings,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Activate Terminal',
      icon: 'check-circle',
      value: MenuOption.ActivateTerminal,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
  ],
};

export const reportsSection: SectionConfig = {
  icon: 'chart-line',
  label: 'OnTrax Reports',
  value: 'reports',
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      icon: 'chart-line',
      label: 'Wash Metrics',
      value: MenuOption.WashMetrics,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      icon: 'chart-line',
      label: 'Lean Daily Management',
      value: MenuOption.LeanDailyManagementReport,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'chart-line',
      label: 'Terminal Reports',
      value: MenuOption.Reports,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      icon: 'soap',
      label: 'Terminal Scorecard - Tank Wash & IBC',
      value: MenuOption.TerminalScorecardWash,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'truck',
      label: 'Terminal Scorecard - Depot & Transportation',
      value: MenuOption.TerminalScorecardDepot,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'industry',
      label: 'Terminal Scorecard - Industrial Services',
      value: MenuOption.TerminalScorecardIndustrial,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'train',
      label: 'Terminal Scorecard - Rail & Specialty',
      value: MenuOption.TerminalScorecardRail,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'recycle',
      label: 'Waste Management',
      value: MenuOption.EhsWasteManagement,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'external-link',
      label: 'Reports Portal',
      value: MenuOption.ReportsPortal,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
  ],
};

export const shiftLogsSection: SectionConfig = {
  icon: 'table',
  label: 'OnTrax Shift Logs',
  value: MenuOption.ShiftLogs,
  visibleByUserType: [UserType.Internal],
  options: [
    {
      icon: 'table',
      label: 'Terminal Shift Logs',
      value: MenuOption.ShiftLogs,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const bulkUploadSection: SectionConfig = {
  label: 'Bulk Upload',
  icon: 'cloud-upload-alt',
  value: MenuOption.BulkUpload,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Download Template',
      icon: 'file-download',
      value: MenuOption.BulkUploadDownloadTemplate,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
  ],
};

export const supportSection: SectionConfig = {
  label: 'Support',
  icon: 'question-circle',
  value: 'support',
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'User Guide',
      icon: 'book',
      value: MenuOption.Wiki,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Customer Training Video',
      icon: 'video',
      value: MenuOption.Video,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
      visibleByUserType: [UserType.AllUserTypes],
    },
    {
      label: 'Terms and Conditions',
      icon: 'book',
      value: MenuOption.Terms,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const ncrSection: SectionConfig = {
  label: 'OnTrax NCR',
  icon: 'file',
  value: MenuOption.Ncr,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Opened',
      icon: 'folder-open',
      value: MenuOption.NcrOpened,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.Customer, RoleName.RvpDepot, RoleName.Manager],
    },
    {
      label: 'In Analysis',
      icon: 'chart-bar',
      value: MenuOption.NcrInAnalysis,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Waiting for Approval',
      icon: 'sync-alt',
      value: MenuOption.NcrWaitingForApproval,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Completed',
      icon: 'check-circle',
      value: MenuOption.NcrCompleted,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
    {
      label: 'Canceled',
      icon: 'ban',
      value: MenuOption.Canceled,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
  ],
};
