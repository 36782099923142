import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private appInstalledSubject = new BehaviorSubject<boolean>(
    (window as any).appInstalled || false
  );

  private beforeInstallPromptSubject = new BehaviorSubject<boolean>(
    !!(window as any).deferredPwaInstallEvent
  );

  private isInstallingSubject = new BehaviorSubject<boolean>(false);

  public appInstalled$ = this.appInstalledSubject.asObservable();

  public beforeInstallPrompt$ = this.beforeInstallPromptSubject.asObservable();

  public isInstalling$ = this.isInstallingSubject.asObservable();

  constructor() {
    fromEvent(window, 'afterAppInstalledEvent').subscribe(() => {
      this.appInstalledSubject.next(true);
      this.beforeInstallPromptSubject.next(false);
      this.isInstallingSubject.next(false);
    });

    fromEvent(window, 'afterBeforeInstallPromptEvent').subscribe(() => {
      this.beforeInstallPromptSubject.next(true);
      this.appInstalledSubject.next(false);
      this.isInstallingSubject.next(false);
    });
  }

  promptInstall(): void {
    const event = (window as any).deferredPwaInstallEvent;
    if (event) {
      event.prompt();
      (window as any).deferredPwaInstallEvent = null;
      this.isInstallingSubject.next(true);
    }
  }
}
