import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { MenuConfig, OptionConfig, SectionConfig } from '../menu.model';
import { MenuService } from '../menu.service';
import { MobileSubMenuComponent } from './mobile-sub-menu/mobile-sub-menu.component';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [MobileSubMenuComponent],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
})
export class MobileMenuComponent implements OnInit {
  @Input() menuConfig: MenuConfig;
  @Input() isVisible: boolean;

  @Output() itemChosen = new EventEmitter<SectionConfig | OptionConfig>();

  currentSubSystemSection: SectionConfig | OptionConfig | undefined;

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentSubSystemSection = this.menuService.getMenuSectionThroughUrl(
      this.menuConfig
    );

    this.router.events.subscribe(() => {
      this.currentSubSystemSection = this.menuService.getMenuSectionThroughUrl(
        this.menuConfig
      );
    });
  }

  onItemChosen($event: SectionConfig | OptionConfig): void {
    this.itemChosen.emit($event);
  }
}
