// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .main-nav-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background-color: #034626;
  color: #034626;
  padding: 0 1.25rem;
}
:host .main-nav-component a {
  text-decoration: none;
}
:host .main-nav-component button {
  border: none;
  background-color: transparent;
}
:host .main-nav-component .main-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: white;
  color: inherit;
  border: 1px solid #008d3e;
  border-radius: 50%;
}
:host .main-nav-component .main-nav-item.home-item {
  width: 2.75rem;
  height: 2.75rem;
  border: 1px solid #008d3e;
  outline: 3px solid white;
  font-size: 1.5rem;
}
:host .main-nav-component .main-nav-item .icon {
  font-size: 1em;
}

::ng-deep .main-nav-user-menu .user-menu-header-button {
  pointer-events: none;
}
::ng-deep .main-nav-user-menu .user-menu-header-button span {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
::ng-deep .main-nav-user-menu .user-menu-icon {
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/global/main-nav/main-nav.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AACI;EACE,qBAAA;AACN;AAEI;EACE,YAAA;EACA,6BAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;AADN;AAGM;EACE,cAAA;EACA,eAAA;EACA,yBAAA;EACA,wBAAA;EACA,iBAAA;AADR;AAIM;EACE,cAAA;AAFR;;AAUI;EACE,oBAAA;AAPN;AASM;EACE,iBAAA;EACA,qBAAA;AAPR;AAWI;EACE,oBAAA;AATN","sourcesContent":[":host {\n  width: 100%;\n\n  .main-nav-component {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 4rem;\n    background-color: #034626;\n    color: #034626;\n    padding: 0 1.25rem;\n\n    a {\n      text-decoration: none;\n    }\n\n    button {\n      border: none;\n      background-color: transparent;\n    }\n\n    .main-nav-item {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 2rem;\n      height: 2rem;\n      background-color: white;\n      color: inherit;\n      border: 1px solid #008d3e;\n      border-radius: 50%;\n\n      &.home-item {\n        width: 2.75rem;\n        height: 2.75rem;\n        border: 1px solid #008d3e;\n        outline: 3px solid white;\n        font-size: 1.5rem;\n      }\n\n      .icon {\n        font-size: 1em;\n      }\n    }\n  }\n}\n\n::ng-deep {\n  .main-nav-user-menu {\n    .user-menu-header-button {\n      pointer-events: none;\n\n      span {\n        font-weight: bold;\n        margin-bottom: 0.5rem;\n      }\n    }\n\n    .user-menu-icon {\n      margin-right: 0.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
